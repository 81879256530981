import { Order } from "./order.interface";
import { OrderResponse } from "./order-response.interface";
import { fetchClient } from "@mealhaus/lib";
import { getEndpoint } from "@mealhaus/utils/get-endpoint.util";
import { ValidateVoucherResponseDto } from "./enum";

async function createOrder(payload): Promise<OrderResponse> {
  return (await fetchClient.postData<OrderResponse>(getEndpoint(`/order`), payload))!;
}

async function validateVoucher(payload): Promise<ValidateVoucherResponseDto> {
  return (await fetchClient.postData<ValidateVoucherResponseDto>(getEndpoint(`/order/voucher/validate`), payload))!;
}

async function getOrders(): Promise<OrderResponse[]> {
  return (await fetchClient.getData<OrderResponse[]>(getEndpoint(`/order`)))!;
}

async function updateOrder(payload): Promise<Order> {
  return (await fetchClient.putData<Order>(getEndpoint(`/order/${payload.id}`), payload))!;
}

export const orderService = {
  createOrder,
  getOrders,
  updateOrder,
  validateVoucher
};
