
import { AddOn } from "@mealhaus/add-ons";
import { Address } from "@mealhaus/address";
import { Recipe } from "src/recipes/recipe.interface";

export enum DeliveryStatus {
  PENDING = "PENDING",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED"
}

export interface OrderResponse {
  readonly id: string;
  readonly address: Address;
  readonly recipe: Recipe;
  readonly totalAmount: number;
  readonly phoneNumber: string;
  readonly deliveryDate: string;
  readonly deliveryStatus: DeliveryStatus;
  readonly orderRecipes: {id:string; quantity:number, recipe: Recipe}[];
  readonly orderAddOns: {id:string; quantity:number, addOn: AddOn}[];
  readonly customerEmail: string;
  readonly discountAmount: number;
}
