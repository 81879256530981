import { queryClient } from "@mealhaus/constants";
import { useMutation } from "react-query";
import { orderService } from "src/orders/order.service";

export const useUpdateOrder = () => {
  return useMutation(orderService.updateOrder, {
    onSuccess: (order) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('order')
    },
  });
};
