import { useState, useEffect } from 'react';
import { getconfig } from '@mealhaus/utils/get-config.util';
import { useAuth0 } from '@auth0/auth0-react';

const config = getconfig()

export function useGetUser() {
      const {
        user, isAuthenticated, isLoading, logout,
        loginWithRedirect,
      } = useAuth0();

    return {user, isAuthenticated, isLoading, login:loginWithRedirect, logout};
}
