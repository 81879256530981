import { queryClient } from "@mealhaus/constants";
import { customerService } from "@mealhaus/customer";
import { useMutation } from "react-query";

export const useUpdateCustomer = () => {
  return useMutation(customerService.updateCustomer, {
    onSuccess: (customer) => {
      queryClient.invalidateQueries("customer");
    },
  });
};
