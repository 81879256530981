import React from "react";
import { QueryClientProvider } from "react-query";
import { getconfig } from "@mealhaus/utils/get-config.util";
import Router from "next/router";
import { queryClient } from "@mealhaus/constants";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { theme } from "../styles/theme";
import { CartProvider } from "@mealhaus/providers/cart";
import { Auth0Provider } from "@auth0/auth0-react";
import * as snippet from '@segment/snippet'
import Script from 'next/script'

const renderSnippet = () => {
  const opts = {
    apiKey: `HgsXoFqmxjTCuJPacQPhflyZFHDJpIft`,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  }

  return snippet.min(opts)
}

Router.events.on("routeChangeComplete", (url) => {
  (window as any)?.analytics?.page(url);
});

const config = getconfig();

const onRedirectCallback = (appState) => {
  if (appState && appState.returnTo) {
    const { asPath, pathname, query } = appState.returnTo;
    return asPath ? Router.push(asPath) : Router.push({ pathname, query });
  }
};

const onRedirecting = () => {
  return <div>loading...</div>;
};


const App = ({ Component, pageProps, router }) => {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <div>
      <Auth0Provider
        domain={config.authDomain}
        clientId={config.clientId}
        redirectUri={config.redirectUri}
        cacheLocation="localstorage"
        audience={config.audience}
        scope=""
        onRedirecting={onRedirecting}
        onRedirectCallback={onRedirectCallback}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CartProvider>
              <CssBaseline />
              <Component {...pageProps} />
            </CartProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Auth0Provider>
      {
        process.env.NODE_ENV !== 'development' &&
        (
          <>
            <Script strategy="afterInteractive" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
          </>
        )
      }
    </div>
  );
}

export default App;
