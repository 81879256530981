import { routes } from "@mealhaus/navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useGetCustomer } from "./use-get-customer-hook";

export function useRedirectIfCustomer() {
  const router = useRouter();
  const { redirect } = router.query;
  const { data: customer, isFetched } = useGetCustomer();

  useEffect(() => {
    if (isFetched && customer && redirect === 'cart') {
      router.push(routes.CART);
      return
    }
    if (isFetched && customer) {
      router.push(routes.HOME);
    }
  }, [isFetched, customer]);
}
