import { queryClient } from "@mealhaus/constants";
import { useMutation } from "react-query";
import { orderService } from "src/orders/order.service";

export const useValidateVoucher = () => {
  return useMutation(orderService.validateVoucher, {
    onSuccess: () => {
      queryClient.invalidateQueries("voucher");
    },
  });
};
