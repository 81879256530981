import { useEffect, useState } from 'react';
import { useGetUser } from "@mealhaus/hooks";

export class Analytics {
    user
    constructor(user = null) {
        this.user = user;
    }
    track = (eventName, payload = {}) => {
        if (typeof window === "undefined" || !(window as any).analytics) {
            return
        }
        (window as any).analytics.track(eventName, { ...payload, user: this.user })
    }
    identify = (userId: string, payload: any = {}) => {
        if (typeof window === "undefined" || !(window as any).analytics) {
            return
        }

        (window as any).analytics.identify(userId, payload)
    }
}

export function useAnalytics() {
    const [analytics, setAnalytics] = useState(new Analytics())
    const { user } = useGetUser();

    useEffect(() => {
        setAnalytics(new Analytics(user));
    }, [user])
    return analytics
}
