import React, { createContext, useEffect, useState } from 'react';
import { useAnalytics } from "@mealhaus/hooks";
import { getImgUrl, getOrderItemDiscountPrice, getOrderTotal } from '@mealhaus/utils/index';
import { TrackingEvents } from '@mealhaus/enums';
import { BoxItem } from '@mealhaus/interfaces';
import { AddOn } from '@mealhaus/add-ons';
import { Recipe } from '@mealhaus/recipes';
import localforage from 'localforage';
import { captureException } from '@sentry/browser';
import { ValidateVoucherResponseDto } from '@mealhaus/orders';

interface Cart {
    mealKits: { [x: string]: BoxItem };
    groceries: { [x: string]: BoxItem }
}

export const CartContext = createContext({
    removeGroceryFromCart: (addOn: AddOn) => { },
    addGroceryToCart: (addOn: AddOn) => { },
    removeMealKitFromCart: (recipe: Recipe) => { },
    addMealKitToCart: (recipe: Recipe) => { },
    box: { mealKits: {}, groceries: {} },
    isMinimumOrder: false,
    orderMealkits: [],
    orderGroceries: [],
    subTotal: 0,
    totalQuantity: 0,
    clearCart: () => { },
    setVoucher: (voucher: ValidateVoucherResponseDto) => { },
    voucherCode: '',
    total: 0
})

const addItemToCart = (box: Cart, category: 'mealKits' | 'groceries') => (item: AddOn | Recipe) => {
    const id = item.id
    const categoryBox = box[category]
    const categoryBoxItem = box[category]?.[id]
    let innerBox;

    if (!!categoryBoxItem) {
        innerBox = {
            ...categoryBox,
            [id]: { ...categoryBoxItem, quantity: categoryBoxItem.quantity + 1 },
        };
    } else {
        innerBox = {
            ...categoryBox,
            [id]: { id, quantity: 1, [category === 'groceries' ? 'addOn' : 'recipe']: item }
        };
    }
    return innerBox

};

const removeItemFromCart = (box: Cart, category: 'mealKits' | 'groceries') => (id: string) => {
    const categoryBox = box[category]
    const categoryBoxItem = box[category]?.[id]
    let innerBox;
    if (!!categoryBoxItem && categoryBoxItem.quantity === 1) {
        const { [id]: currentItem, ...restOfIds } = categoryBox;
        innerBox = restOfIds;
    }
    if (!!categoryBoxItem && categoryBoxItem.quantity > 1) {
        innerBox = {
            ...categoryBox,
            [id]: { ...categoryBoxItem, quantity: categoryBoxItem.quantity - 1 },
        }
    }
    return innerBox
};

export const CartProvider = ({ children }: any) => {
    const [box, setBox] = useState<Cart>({ mealKits: {}, groceries: {} });
    const [voucher, setVoucher] = useState<ValidateVoucherResponseDto>({ isValid: false });
    const analytics = useAnalytics()

    const groceriesItems = React.useMemo(() => {
        return Object.values(box.groceries).map(item => {
            const addOn = item.addOn
            const price = addOn.amount;
            const discountPrice =
                voucher.isValid && voucher.voucher.addOn?.id === addOn.id
                    ? getOrderItemDiscountPrice(
                        price,
                        voucher.voucher,
                    )
                    : price;

            return {
                ...item,
                price,
                discountPrice
            };
        })
    }, [box, voucher])

    const mealkitItems = React.useMemo(() => {
        return Object.values(box.mealKits).map(item => {
            const recipe = item.recipe
            const price = recipe.amount;

            return {
                ...item,
                price,
                discountPrice: price
            };
        })
    }, [box, voucher])

    const subTotal = groceriesItems.concat(mealkitItems).reduce((acc, item) => {
        const amount = item.price;
        return acc + item.quantity * amount;
    }, 0);

    const discountTotalAmount = groceriesItems.concat(mealkitItems).reduce((acc, item) => {
        const amount = item.discountPrice;
        return acc + item.quantity * amount;
    }, 0);

    const total = getOrderTotal(subTotal,discountTotalAmount, voucher)
    const discount = subTotal - total;

    const totalQuantity = groceriesItems.concat(mealkitItems).reduce((acc, item) => {
        return acc + item.quantity
    }, 0);

    const isMinimumOrder = subTotal >= 2000;
    const orderMealkits = mealkitItems.filter((orderItem) => orderItem.recipe);
    const orderGroceries = groceriesItems.filter((orderItem) => orderItem.addOn);

    useEffect(() => {
        if (discount > 0) {
            const voucherCode = voucher.voucher?.code
            analytics.track(TrackingEvents.CouponApplied,{discount, coupon_id: voucherCode, coupon_name:voucherCode })
        }
    }, [discount])

    useEffect(() => {
        (async () => {
            try {
                await localforage.setItem('cart', box)
            } catch (error) {
                captureException(error);
            }
        })()
    }, [box])

    useEffect(() => {
        (async () => {
            try {
                const value = await localforage.getItem('cart') as any;
                value && setBox(value)
            } catch (error) {
                captureException(error);
            }
        })()
    }, [])

    const addMealKitToCart = (recipe: Recipe) => {
        const mealKitCart = addItemToCart(box, 'mealKits')(recipe)
        setBox({ ...box, mealKits: mealKitCart });
        const { amount, image, id } = recipe;
        analytics.track(TrackingEvents.ProductAdded, {
            product_id: id,
            product: { title: recipe?.title },
            sku: id,
            price: amount,
            value: amount * mealKitCart[id].quantity,
            category: "recipe",
            quantity: mealKitCart[id].quantity,
            currency: "NGN",
            image_url: getImgUrl(`recipes/${image}`),
        });
    }

    const removeMealKitFromCart = (recipe: Recipe) => {
        const mealKitCart = removeItemFromCart(box, 'mealKits')(recipe.id)
        setBox({ ...box, mealKits: mealKitCart });
        const { amount, image, id } = recipe;
        analytics.track(TrackingEvents.ProductRemoved, {
            product_id: id,
            product: { title: recipe?.title },
            sku: id,
            price: amount,
            category: "recipe",
            quantity: 1,
            currency: "NGN",
            image_url: getImgUrl(`recipes/${image}`),
        });
    }

    const addGroceryToCart = (addOn: AddOn) => {
        const groceriesCart = addItemToCart(box, 'groceries')(addOn)
        setBox({ ...box, groceries: groceriesCart });
        const { amount, image, id } = addOn;
        analytics.track(TrackingEvents.ProductAdded, {
            product_id: id,
            product: { title: addOn?.title },
            sku: id,
            price: amount,
            value: amount * groceriesCart[id].quantity,
            category: "groceries",
            quantity: groceriesCart[id].quantity,
            currency: "NGN",
            image_url: getImgUrl(`addOns/${image}`),
        });
    }

    const removeGroceryFromCart = (addOn: AddOn) => {
        const groceriesCart = removeItemFromCart(box, 'groceries')(addOn.id)
        setBox({ ...box, groceries: groceriesCart });
        const { amount, image, id } = addOn;
        analytics.track(TrackingEvents.ProductRemoved, {
            product_id: id,
            product: { title: addOn?.title },
            sku: id,
            price: amount,
            value: amount,
            category: "groceries",
            quantity: 1,
            currency: "NGN",
            image_url: getImgUrl(`addOns/${image}`),
        });
    }

    const clearCart = () => {
        setBox({ mealKits: {}, groceries: {} })
        setVoucher({ isValid: false })
    }

    const props = {
        isMinimumOrder,
        orderMealkits,
        orderGroceries,
        subTotal,
        totalQuantity,
        clearCart,
        setVoucher,
        voucherCode: voucher.voucher?.code,
        total
    }

    return <CartContext.Provider value={{ removeGroceryFromCart, addGroceryToCart, removeMealKitFromCart, addMealKitToCart, box, ...props }}>{children}</CartContext.Provider>;
};
