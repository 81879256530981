import React, { Fragment } from 'react';
import Link from 'next/link'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { routes } from '.';
import { getImgUrl } from '@mealhaus/utils/get-img-url.util';
import Imgix from "react-imgix";

interface Props { }

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.common.white,
        },
    })
);
export const OnboardingNavBar = ({ }: Props) => {
    const classes = useStyles()

    return (<Fragment>
        <Box p={2} className={classes.paper}>
            <nav>
                <Link href={routes.HOME}>
                    <a className="z-index-99">
                        <Imgix
                            src={getImgUrl(`static/logo2.png`)}
                            imgixParams={{ auto: "format,compress", q: 10, h: 25, w: 120 }}
                            width={120}
                            className="logo"
                            alt="LOGO"
                        />
                    </a>
                </Link>
            </nav>
        </Box>
        <style jsx>
            {`
            .logo {
                    max-width: 120px;
                    vertical-align: middle;
                    display: inline-block;
                    }
                    .z-index-99{
                        z-index: 99
                    }
                `}
        </style>
    </Fragment>)


}
