import { addressService } from "@mealhaus/address";
import { queryClient } from "@mealhaus/constants";
import { useMutation } from "react-query";

export const useDeleteAddress = () => {
  return useMutation(addressService.deleteAddress, {
    onSuccess: (address) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('addresses')
    },
  });
};
