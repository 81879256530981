import { fetchClient } from "@mealhaus/lib";
import { getEndpoint } from "@mealhaus/utils/get-endpoint.util";
import { Customer } from "./customer.interface";

async function getCustomer(): Promise<Customer> {
  return (await fetchClient.getData<Customer>(getEndpoint(`/customer`)))!;
}
async function createCustomer(customer : Pick<Customer, "deliveryDate" | "phoneNumber">): Promise<Customer> {
  return (await fetchClient.postData<Customer>(getEndpoint(`/customer`),customer))!;
}

async function updateCustomer(customer : Pick<Customer, "deliveryDate" | "phoneNumber" | "address">): Promise<Customer> {
  return (await fetchClient.putData<Customer>(getEndpoint(`/customer`),customer))!;
}

export const customerService = {
  getCustomer,
  createCustomer,
  updateCustomer
};
