import { customerService } from "@mealhaus/customer";
import { useMutation } from "react-query";
import { queryClient } from "@mealhaus/constants";
import { useAnalytics } from "./use-analytics";
import { TrackingEvents } from "@mealhaus/enums";

export const useCreateCustomer = () => {
  const analytics = useAnalytics();

  return useMutation(customerService.createCustomer, {
    onSuccess: (customer) => {
      queryClient.invalidateQueries("customer");
      analytics.identify(customer.id,{phone: customer.phoneNumber, street: customer.address.street, neighborhood: customer.address.neighborhood, city: customer.address.city})
      analytics.track(TrackingEvents.RegisterationCompleted)
    },
  });
};
