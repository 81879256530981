import { ValidateVoucherResponseDto, Voucher, VoucherTypes } from "@mealhaus/orders"

export const getOrderItemDiscountPrice = (price: number, voucher: Voucher) => {
    switch (voucher.type) {
        case VoucherTypes.FIXED:
            return Number(price) - Number(voucher.value)
        case VoucherTypes.PERCENTAGE:
            return Number(price) - ((Number(voucher.value) / 100) * price)
        default:
            return price
    }
}

export const getOrderDiscountPrice = (price: number, voucher: ValidateVoucherResponseDto) => {
    switch (voucher.voucher.type) {
        case VoucherTypes.FIXED:
            return Number(price) - Number(voucher.voucher.value)
        case VoucherTypes.PERCENTAGE:
            return Number(price) - ((Number(voucher.voucher.value) / 100) * price)
        default:
            return price
    }
}

export const getOrderTotal = (subTotal: number, discountTotalAmount: number, voucher: ValidateVoucherResponseDto) => {
    if (!voucher.isValid) {
        return subTotal
    }
    if (!!voucher.voucher && voucher.voucher.addOn) {
        return discountTotalAmount
    }
    return getOrderDiscountPrice(subTotal, voucher)
}
