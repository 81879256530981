import { AddOn } from "@mealhaus/add-ons";

export interface ValidateVoucherResponseDto {
    isValid: boolean;
    error?: Error;
    voucher?: Voucher;

}

export interface Voucher {
    value: number;
    addOn: AddOn;
    type: VoucherTypes;
    code: string;
}

export enum VoucherTypes {
    FIXED = 'FIXED',
    PERCENTAGE = 'PERCENTAGE',
    DELIVERY = 'DELIVERY'
}

export enum UsageLimitType {
    TOTAL_USAGE = 'TOTAL_USAGE',
    USAGE_PER_USER = 'USAGE_PER_USER'
}

export enum MinimumRequirementsOptions {
    MINIMUM_QUANTITY = 'MINIMUM_QUANTITY',
    MINIMUM_ORDER_VALUE = 'MINIMUM_ORDER_VALUE',
    NONE = "NONE"
}
