import React, { useContext, useEffect } from "react";
import { Recipe } from "../recipe.interface";
import { getImgUrl } from "@mealhaus/utils/get-img-url.util";
import lazySizes from "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import {
  CardContent,
  CardMedia,
  Card,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Link from "next/link";
import { CartContext } from "@mealhaus/providers/cart";
import classNames from "classnames";
import { buildURL } from "react-imgix";
lazySizes.cfg.init = false;

interface Props {
  recipe: Recipe;
  url: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 140,
      width: '100%'
    },
    divider: {
      margin: theme.spacing(1.5, 0),
    },
    button: {
      padding: "5px 15px",
      minHeight: 32,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    flexGrow1: {
      display: "flex",
      flexGrow: 1,
    },
    title:{
      textTransform:"capitalize"
    },
    pointer:{
      cursor: "pointer"
    }
  })
);

function lazyLoad(e: any) {
  var bg = e.target.getAttribute('data-bg');
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')';
  }
}

export const RecipeCardWithButton = ({ recipe, url }: Props) => {
  const classes = useStyles();
  const {
    title,
    duration,

    image,
    subTitle,
    id,
    amount,
  } = recipe;
  const { addMealKitToCart, removeMealKitFromCart, box } = useContext(
    CartContext
  );
  const boxItem = (() => box["mealKits"]?.[recipe.id])();

  useEffect(() => {
    document.addEventListener('lazybeforeunveil', lazyLoad);
    lazySizes.init();
    return function removeListener() {
      document.removeEventListener('lazybeforeunveil', lazyLoad);
    };
  }, [])

  return (
    <Card variant="outlined">
      <Link href={url}>
        <Box className={classes.pointer}>
          <CardMedia
            data-bg={buildURL(getImgUrl(`recipes/${image}`), { q: 5, auto:"format,compress", w:300, h: 300 })}
            component="div"
            className={classNames(classes.media, "lazyload")}
          />
        </Box>
      </Link>
      <CardContent>
        <Link href={url}>
          <Box className={classes.pointer}>
            <Typography className={classes.title} variant="body2" noWrap>
              {title}
            </Typography>
            <Typography noWrap color="textSecondary" variant="subtitle2">
                  {subTitle}
                </Typography>
          </Box>
        </Link>
        {/* <Divider light className={classes.divider} /> */}
        <Box display='flex' justifyContent='space-between' alignItems='center'>
        {/* <Chip size="small" label={`under ${duration} mins`} /> */}
          <Typography variant="body2">
            ₦{Number(amount).toLocaleString()}
          </Typography>
        </Box>
        <Box marginTop="1rem">
          <Button
            variant={!!boxItem ? "contained" : "outlined"}
            color="primary"
            fullWidth
            startIcon={!!boxItem ? <RemoveIcon onClick={() => removeMealKitFromCart(recipe)} /> : null}
            endIcon={<AddIcon onClick={() => addMealKitToCart(recipe)} />}
            className={classes.button}
            disableRipple
          >
            <Box flexGrow={1} onClick={() => !boxItem && addMealKitToCart(recipe)}>{!!boxItem ? `${boxItem.quantity}` : `Add`}</Box>
          </Button>
        </Box>
      </CardContent>
    </Card >
  );
};
