import { loadScript } from '@mealhaus/utils/load-script';
import { useState, useEffect } from 'react';

export function useGooglePlaces() {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(()=>{
        checkIsLoaded()
    },[])

    function checkIsLoaded() {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFNMvqV4DDfZXiD9FApU3s0Sv0bLKsFaA&libraries=places",
                document.querySelector("head"),
                "google-maps",
                () => {
                    setIsLoaded(true);
                }
            );
        }
        else if (window.google) {
            setIsLoaded(true);
        }
        else {
            setTimeout(() => setIsLoaded(true), 1000)
        }
    }

    return isLoaded;
}
