import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#86bf40',
      main: '#26743a',
    },
    secondary: {
      light: '#0066ff',
      main: 'rgba(240, 117, 34, 1)'
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '2.125rem',
      lineHeight: 3.188,
      fontWeight: 400,
      letterSpacing: '0.016rem',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: 2,
      fontWeight: 400,
      letterSpacing: '0rem',
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: 1.875,
      fontWeight: 400,
      letterSpacing: '0.0075rem',
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: 1.688,
      fontWeight: 400,
      letterSpacing: '0.0075rem',
    },
    h5: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 500,
      letterSpacing: '0.009rem',
    },
    h6: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: '0.009rem',
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: '0.009rem',
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.313,
      fontWeight: 400,
      letterSpacing: '0.009rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      letterSpacing: '0.009rem',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.313,
      fontWeight: 400,
      letterSpacing: '0.009rem',
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: 1.313,
      fontWeight: 500,
      letterSpacing: '0.078rem',
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.125,
      fontWeight: 400,
      letterSpacing: '0.025rem',
    },
  },
  props:{
    MuiTextField: {
      color: 'primary',
      size: 'small',
      type: 'text',
      variant: 'standard',
  },
  MuiInputLabel:{
    shrink: true,
  }
  },
  overrides: {
    MuiButtonBase: {
      root: {
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        },
        '&:focus': {
          outline: 'none',
        },
        textTransform: 'none'
      },
    },
    MuiButton: {
      root: {
        '&:disabled': {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        },
        '&:focus': {
          outline: 'none',
        },
        textTransform: 'none',
        paddingTop: '1rem',
        paddingBottom:'1rem'
      },
      contained: {
        '&:disabled': {
          color: 'white',
          boxShadow: 'none',
          backgroundColor: '#616161'
        }
      },
      outlined:{
        padding: '1rem'
      }
    },
    MuiAppBar: {
      root: {
        borderBottom: `1px solid rgba(0, 0, 0, 0.12)`
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      },
    },
    MuiDivider: {
      root: {
        marginTop: `.5rem`,
        marginBottom: `.5rem`
      }
    },
    MuiFormControlLabel: {
      label: {
          fontSize: '1rem',
      },
  },
  MuiInputBase: {
    root: {
        letterSpacing: 'normal',
    },
},
MuiInput: {
    root: {
        height: '2rem',
    },
    input: {
        boxSizing: 'border-box',
        height: '2rem',
        fontSize: '0.875rem',
    },
    multiline: {
        height: 'auto',
        resize: 'none',
        padding: '1rem',
    },
    inputMultiline: {
        height: 'auto',
        resize: 'none',
    },
},
  }
});
