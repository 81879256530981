import { fetchClient } from "@mealhaus/lib";
import { getEndpoint } from "@mealhaus/utils/get-endpoint.util";
import { Address } from "./address.interface";

async function createAddress(payload): Promise<Address> {
  return (await fetchClient.postData<Address>(getEndpoint(`/address`),payload))!;
}

async function getAddresses(): Promise<Address[]> {
  return (await fetchClient.getData<Address[]>(getEndpoint(`/address`)))!;
}

async function deleteAddress(id:string): Promise<void> {
  return (await fetchClient.delete<void>(getEndpoint(`/address/${id}`)))!;
}

export const addressService = {
  createAddress,
  getAddresses,
  deleteAddress
};
