import { useInfiniteQuery } from 'react-query';
import { buildQueryParams } from '@mealhaus/utils/build-query-params.util';
import { getCategories } from '@mealhaus/product-categories/service';

export const useGetCategories = () => {
    return useInfiniteQuery(
        ['categories'],
        async ({ pageParam = 1 }) => {
            const query = buildQueryParams({ page: pageParam || 1, limit: 3 });
            const dto = await getCategories(query);
            return { ...dto, items: dto.items };
        },
        {
            getNextPageParam: (lastPage, ...r) => {
                return !lastPage.last ? lastPage.page + 1 : undefined;
            },
        }
    );
};
