import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Address } from '..';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        checkboxRoot:{
            minWidth: 0
        },
        chip:{
            marginRight: 4,
        }
    }),
);

interface Props {
    selectedId: string;
    addresses: Address[];
    onDelete: (id: string) => void;
    onSelect: (address: Address) => void;
}

export function AddressSelectList({ addresses, selectedId, onSelect, onDelete }: Props) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {addresses.map((address) => {
                const labelId = `checkbox-list-label-${address.id}`;
                const isSmartAddress = address.coordinates?.lat && address.coordinates?.lng;
                const isSelected = address.id === selectedId;
                return (
                    <ListItem key={address.id} role={undefined} dense button onClick={() => onSelect(address)}>
                        <ListItemIcon className={classes.checkboxRoot}>
                            <Checkbox
                                edge="start"
                                checked={isSelected}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={address.street || (address as any).address} />
                        {
                            isSmartAddress && (
                                <Chip
                                    size="small"
                                    label="SM"
                                    clickable
                                    color="primary"
                                    className={classes.chip}
                                />
                            )
                        }
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" disabled={isSelected} onClick={() => onDelete(address.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
    );
}
