import { PaginatedResponse } from "@mealhaus/interfaces";
import { fetchClient } from "@mealhaus/lib";
import { getEndpoint } from "@mealhaus/utils/get-endpoint.util";
import { Category } from "./interfaces";

export async function getCategories(query?: string): Promise<PaginatedResponse<Category>> {
  return (await fetchClient.getData<PaginatedResponse<Category>>(getEndpoint(`/product-category?${query}`)))!;
}

export async function getAllCategories(): Promise<Category[]> {
  return (await fetchClient.getData<Category[]>(getEndpoint(`/product-category/all`)))!;
}

export const service = {
    getCategories
};
