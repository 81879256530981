export const ValidationMessages = {
    Required: "required",
    invalidPhoneNumber: "invalid phone number",
    email: "invalid email",
};

export enum TrackingEvents {
    GetStarted = "Get Started",
    OrderCompleted = "Order Completed",
    OrderCreated = "Order Created",
    ProductAdded = "Product Added",
    ProductRemoved = "Product Removed",
    ProductViewed = "Product Viewed",
    RegisterationCompleted = "Registeration Completed",
    RedirectedToSignUp = "Redirected To SignUp",
    CheckoutStarted = "Checkout Started",
    CartViewed = "Cart Viewed",
    CouponApplied = "Coupon Applied",
    CouponEntered = "Coupon Entered",
    ProductsSearched = "Products Searched"
}
