import { OrderResponse } from "@mealhaus/orders";
import { getconfig } from "./get-config.util";

const config = getconfig()

interface PaystackInterface {
    key: string;
    email: string;
    amount: number;
    ref: string;
    currency: 'NGN';
    onSuccess: () => void;
    onCancel: () => void;

  }

  export function payWithPaystack(order: OrderResponse, callback, onClose) {
    const { customerEmail, discountAmount, id } = order
    const paystackConfig = getPaystackConfig({
      key: config.paystackPublicKey,
      email: customerEmail,
      amount: discountAmount,
      currency: "NGN",
      ref: id,
      onSuccess: callback,
      onCancel: onClose,
    });
    callPaystackPop(paystackConfig)
  }

  const callPaystackPop = (paystackArgs: PaystackInterface): void => {
    const Window = window as any
    const paystack =Window.PaystackPop && new Window.PaystackPop();
    paystack.newTransaction(paystackArgs);
  };

  export const getPaystackConfig = (config: PaystackInterface) => {
    return { ...config, amount: config.amount * 100 }
  }
