import { addressService } from "@mealhaus/address";
import { useQuery } from "react-query";

export const useGetAddresses = () => {
  return useQuery("addresses", addressService.getAddresses, {
    onSuccess: (addresses) => {

    },
  });
};
