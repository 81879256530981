import { AddOn } from "./add-on.interface";
import { fetchClient } from "@mealhaus/lib";
import { getEndpoint } from "@mealhaus/utils/get-endpoint.util";

export async function getAddOns(categoryId?: string): Promise<AddOn[]> {
  return (await fetchClient.getData<AddOn[]>(getEndpoint(`/add-ons?categoryId=${categoryId??''}`)))!;
}

export async function getTopSellers(): Promise<AddOn[]> {
  return (await fetchClient.getData<AddOn[]>(getEndpoint(`/add-ons/top-sellers`)))!;
}

async function searchAddOns(query: string): Promise<AddOn[]> {
  return (await fetchClient.getData<AddOn[]>(getEndpoint(`/add-ons/search?query=${query??''}`)))!;
}

export async function getAddOn(addOnId: string): Promise<AddOn> {
  return (await fetchClient.getData<AddOn>(getEndpoint(`/add-ons/${addOnId}`)))!;
}

export const addOnService = {
  getAddOns,
  getAddOn,
  searchAddOns,
  getTopSellers
};
