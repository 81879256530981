import { customerService } from "@mealhaus/customer";
import { useQuery } from "react-query";
import { setUser } from '@sentry/browser';
import { useAnalytics } from "./use-analytics";

export const useGetCustomer = () => {
  const analytics = useAnalytics();
  return useQuery("customer", customerService.getCustomer, {
    onSuccess: (customer) => {
      setUser({ id: customer.id });
      analytics.identify(customer.id,{phone: customer.phoneNumber, street: customer?.address.street, neighborhood: customer?.address.neighborhood, city: customer?.address.city})
    },
  });
};
