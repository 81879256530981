import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useCheckMobile, useGetUser } from "@mealhaus/hooks";
import { useRouter } from "next/router";
import { MenuItem, MenuList } from "@material-ui/core";
import { routes } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  menuRoot:{
    zIndex: theme.zIndex.drawer + 1
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  btn: {
    display: "flex",
    textTransform: "none",
    "&:focus": {
      outline: "none"
    }
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(0, 1),
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  },
  downChevron: {
    transform: (props: any) =>
      props.isOpen
        ? `rotate(
  180deg
  )`
        : "none",
    transition: "transform 0.3s ease 0s"
  }
}));

function UserMenu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles({ isOpen: open });
  const { user, isLoading, logout, isAuthenticated, login } = useGetUser()
  const { pathname, query, asPath, push } = useRouter();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleNavigation = route => (event) => {
    handleClose(event)
    push(route)
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const logoutWrapper = () => logout({ returnTo: process.env.NEXT_PUBLIC_LOGOUT_URI })

  const showName = !useCheckMobile(1200)
  let handleAuth, authLabel

  if (isAuthenticated) {
    handleAuth = () => logout({ returnTo: process.env.NEXT_PUBLIC_LOGOUT_URI });
    authLabel = "Logout";
  } else {
    handleAuth = () =>
      login({ appState: { returnTo: { pathname, query, asPath } } });
    authLabel = "Login";
  }

  if (!!user) {
    return (
      <div className={classes.root}>
          <Button
            className={classes.btn}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <div className={classes.avatarWrapper}>
              <Avatar
                className={classes.avatar}
                alt={user.email}
                src={user.picture}
              />
              {showName && user.given_name}
            </div>
            <KeyboardArrowDownIcon className={classes.downChevron} />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-end"
            transition
            className={classes.menuRoot}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleNavigation(routes.HOME)}>Home</MenuItem>
                      <MenuItem onClick={handleNavigation(routes.ACCOUNT_SETTINGS)}>Account settings</MenuItem>
                      {/* <MenuItem onClick={handleNavigation(routes.MEAL_KITS)}>Menu</MenuItem> */}
                      <MenuItem onClick={logoutWrapper}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
      </div>
    );
  }
  return (
    <Button variant='outlined' color='primary' onClick={handleAuth}>
      {authLabel}
    </Button>
  )
}
export default UserMenu;
