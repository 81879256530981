import { queryClient } from "@mealhaus/constants";
import { TrackingEvents } from "@mealhaus/enums";
import { useMutation } from "react-query";
import { orderService } from "src/orders/order.service";
import { useAnalytics } from "./use-analytics";

export const useCreateOrder = () => {
  const analytics = useAnalytics();

  return useMutation(orderService.createOrder, {
    onSuccess: (payload) => {
      // Invalidate and refetch
      queryClient.invalidateQueries('order')
      analytics.track(TrackingEvents.OrderCreated, {
        order_id: payload.id,
        products: payload.orderRecipes.map(
          ({ recipe, quantity }) => ({ product_id: recipe.id, sku: recipe.id, name: recipe.title, quantity, price: recipe.amount, image_url: recipe.image })
        ).concat(
          payload.orderAddOns.map(
            ({ addOn, quantity }) => ({ product_id: addOn.id, sku: addOn.id, name: addOn.title, quantity, price: addOn.amount, image_url: addOn.image }))
          ),
        total: payload.totalAmount,
        currency: "NGN"
      })
    },
  });
};
