export function loadScript(src: string, position: HTMLElement | null, id: string, cb) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    script.onload = cb;
    position.appendChild(script);
  }
