import { addOnService } from "@mealhaus/add-ons";
import { useQuery } from "react-query";

export const useSearchAddOns = (params:{query: string; enabled:boolean}) => {
  const {query, enabled} = params

  return useQuery(["addOns", query], () => addOnService.searchAddOns(query), {
    onSuccess: (addOns) => {

    },
    enabled
  });
};
