import { routes } from "@mealhaus/navigation";
import { isCustomerComplete } from "@mealhaus/utils/is-customer-complete.util";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useGetCustomer } from "./use-get-customer-hook";

export function useRedirectIncompleteCustomer() {
  const router = useRouter();
  const { data: customer, isFetched } = useGetCustomer();

  useEffect(() => {
    if (isFetched && customer && !isCustomerComplete(customer)) {
      router.push(routes.ACCOUNT_SETTINGS);
    }
  }, [isFetched, customer]);
}
