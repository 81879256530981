import { Address } from "@mealhaus/address";
import { DATE_FORMAT } from "@mealhaus/constants";
import { addDays, format, getDay } from "date-fns";
import {  captureException } from '@sentry/browser';

export function getDeliveryDate(date: Date) {
  let deliveryDate = addDays(date, 1);
  if (getDay(deliveryDate) === 0) {
    deliveryDate = addDays(deliveryDate, 1);
  }
  return deliveryDate;
}

export function getSmartDeliveryDate(date: Date, isSamedayDelivery) {
  let deliveryDate: Date;

  if (isSamedayDelivery) {
    deliveryDate = date
  }
  else{
    deliveryDate = addDays(date, 1);
  }

  if (getDay(deliveryDate) === 0) {
    deliveryDate = addDays(deliveryDate, 1);
  }
  return deliveryDate;
}

export function getDeliveryDateFormatted(
  deliveryDate: Date,
  dateFormat = DATE_FORMAT
) {
  return format(deliveryDate, dateFormat);
}

export function getDeliveryDistance({ lat, lng }, callback) {
  new window.google.maps.DistanceMatrixService().getDistanceMatrix(
    {
      origins: [new google.maps.LatLng(6.5043268, 3.5997843)], // Ajah hub
      destinations: [new google.maps.LatLng(6.4358255, 3.4449659),new google.maps.LatLng(lat, lng)], // ['Lekki toll gate address', 'customer address']
      travelMode: google.maps.TravelMode.DRIVING,
    },
    (response, status) => {
      if (status === google.maps.DistanceMatrixStatus.OK) {
        const {
          status,
        } = response.rows[0].elements[0];
        const {
          status: customerAddressStatus,
        } = response.rows[0].elements[1];

        if(status === "OK" && customerAddressStatus === "OK"){
          const {
            distance: { value: radius },
          } = response.rows[0].elements[0];
          const {
            distance: { value: distanceToCustomer },
          } = response.rows[0].elements[1];
          callback ([radius, distanceToCustomer]);
          return
        }

        callback ([0, 1]);
      }
      else{
        callback ([0, 1]);
        captureException(`DistanceMatrixStatus failed with ${google.maps.DistanceMatrixStatus.OK}`)
      }
    }
  );
}

export const getIsSamedayDelivery = ([radius, customerLocation]: [number,number] )=>{
  // Distance to customer location is less than radius and hour of the day is less than 16
 return customerLocation <= radius && new Date().getHours() < 16
}

export const getIsSmartAddress = (address: Address )=>{
  return !!address?.coordinates?.lat && !!address?.coordinates?.lng;
}

export const hasSmartAddress = (address: Address[] )=>{
  return address.some(getIsSmartAddress)
}
