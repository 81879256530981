export * from './OnboardingNavBar'
export * from './Menu'
export enum routes {
    HOME = "/app",
    ACCOUNT_ADDRESS = "/account/address",
    ACCOUNT_SETTINGS = "/account/settings",
    GROCERIES = "/app/groceries",
    MEAL_KITS = "/app/meal-kits",
    CART = "/app/cart",
    CATEGORIES = "/app/categories"
  }
