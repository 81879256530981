import { routes } from "@mealhaus/navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useGetCustomer } from "./use-get-customer-hook";

export function useRedirectIfNotCustomer() {
  const router = useRouter();
  const { data: customer, isSuccess } = useGetCustomer();
  useEffect(() => {
    if (isSuccess && !customer) {
      router.push(routes.ACCOUNT_ADDRESS);
    }
  }, [isSuccess, customer]);
}
